<template>
  <div class="page-box" style='position:relative' >
    <a-button type='link' icon='reload' class='refresh-btn' @click='getShopRecommendData'>刷新</a-button>
    <a-spin :spinning="headLoading" >
      <a-row class="page-head">
        <a-col :span="6" class="head-item">
          <p class="label">
            我的累计奖励（元）
            <a-button type="link" @click="goRecordPage($event)" style="padding:0;line-height:24px;height:24px;">查看奖励明细</a-button>
          </p>
          <div class="withdrawal-box">
            <span class="value">{{shopRecommendData.totalAmount}}</span>
          </div>
        </a-col>
        <a-col :span="6" class="head-item">
          <p class="label">已到账总金额（元）</p>
          <div class="value">{{shopRecommendData.arrivalAmount}}</div>
        </a-col>
        <a-col :span="6" class="head-item">
          <p class="label">未到账总金额（元）</p>
          <div class="value">{{shopRecommendData.notArrivalAmount}}</div>
        </a-col>
        <a-col :span="6" class="head-item">
          <p class="label">发展供应商人数</p>
          <div class="value">{{shopRecommendData.developSupplyNum}}</div>
        </a-col>
      </a-row>

    </a-spin>
    <base-screen style='margin-top:24px;'>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="商户名称" prop='supplyShopId'>
          <a-select v-model="params.supplyShopId " placeholder="请选择" style="min-width: 160px">
            <a-select-option v-for='item in shopList' :key='item.shopId' :value='item.shopId'>{{item.name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="所属员工" prop='shopStaffId'>
          <a-select v-model="params.shopStaffId" placeholder="请选择" style="min-width: 240px">
            <a-select-option v-for='item in staffList' :key='item.shopStaffId' :value='item.shopStaffId'>{{item.loginName}} {{item.realName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <a-table :columns="columns" bordered :dataSource="tableData" :rowKey="record => record.shopId" :loading="tableLoading" :pagination='pagination' @change="handleTableChange">
      <template slot="name" slot-scope="text,record">
        <div class="goods-info">
          <base-img class="goods-img" :src="record.logo" width="60" height="60" oss-style="60_60" />
          <div>
            <p @click="goShopDetail(record.shopId)" class="goods-name shop-name el2">{{record.name}}</p>
            <span :style="{fontSize: '13px', color: shopAuthStateFormat(record.authState||0, 'color')}">{{shopAuthStateFormat(record.authState||0, 'text')}}</span>
          </div>
        </div>
      </template>
      <template slot="loginName" slot-scope="text,record">
        <div>{{record.realName}}</div>
        <div>{{record.loginName}}</div>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click='goRecordPage($event,record.shopId)'>查看订单</a-button>
      </div>
    </a-table>
  </div>
</template>
<script>
import NP from 'number-precision'
import { capital, staff } from '@/api'
import { onMounted, reactive, toRefs, ref } from '@vue/composition-api'
import { SHOPID, SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
import { shopAuthStates } from '@/utils/constants'
export default {
  name: 'ShopInfo',
  setup (props, { root }) {
    const columns = [
      {
        title: '商户名称',
        width: 200,
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '推荐人',
        dataIndex: 'loginName',
        scopedSlots: { customRender: 'loginName' },
      },
      {
        title: '已到账金额',
        dataIndex: 'arrivalAmount',
      },
      {
        title: '未到账金额',
        dataIndex: 'notArrivalAmount',
      },
      {
        title: '生效时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
        width: 140,
      },
    ]
    onMounted(() => {
      getShopRecommendData()
      getStaffList()
      getSupplyShopList()
      getTableData()
    })
    const state = reactive({
      headLoading: false,
      authVisible: false,
      authModalLoading: false,
      tableLoading: false,
      shopRecommendData: {},
      params: {
        shopStaffId: undefined,
        supplyShopId: undefined
      },
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      formRef: null,
      staffList: [],
      shopList: [],
    })
    async function getShopRecommendData () {
      state.headLoading = true
      let { code, data, msg } = await capital.getRecommendData({
        shopId: getSession(SHOPID),
      })
      state.headLoading = false
      if (code === '00000') {
        data.notArrivalAmount = NP.round(data.notArrivalAmount || 0, 2).toFixed(2)
        data.totalAmount = NP.round(data.totalAmount || 0, 2).toFixed(2)
        data.arrivalAmount = NP.round(data.arrivalAmount || 0, 2).toFixed(2)
        state.shopRecommendData = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function goRecordPage (e, shopId) {
      if (shopId) {
        root.$router.push('/capital/recommend/record/' + shopId)
      } else {
        root.$router.push('/capital/recommend/record')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }
    async function getTableData () {
      state.tableLoading = true
      let { code, data, msg, page } = await capital.getSettlePage({
        shopId: getSession(SHOPID),
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.params,
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleSearch () {
      state.pagination.current = 0
      getTableData()
    }
    async function getSupplyShopList () {
      const { data, code } = await capital.getSupplyShopList({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.shopList = data
      }
    }
    function shopAuthStateFormat (state, type) {
      const matching = shopAuthStates.find((x) => x.value === +state)
      if (type === 'text') return matching ? matching.name : ''
      if (type === 'color') return matching ? matching.color : ''
    }
    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }
    async function getStaffList () {
      const { data, code } = await staff.getSelectStaffData({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.staffList = data
      }
    }
    function goShopDetail (id) {
      if (id === getSession(SHOPID)) {
        root.$router.push('/shop/info')
      } else {
        root.$router.push('/goods/shop_goods_list/' + id)
      }
    }
    return {
      columns,
      ...toRefs(state),
      getShopRecommendData,
      goRecordPage,
      handleTableChange,
      handleSearch,
      handleReset,
      getStaffList,
      shopAuthStateFormat,
      goShopDetail,
      getSupplyShopList,
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-form-item {
  margin-bottom: 0px;
}
.page-head {
  .head-item {
    padding: 8px 40px;
    border-right: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 84px;
    .value {
      font-weight: 500;
      font-size: 24px;
    }
    .label {
      font-size: 14px;
      color: #666;
      line-height: 24px;
    }
    &:last-child {
      border-right: 0;
    }
    .withdrawal-box {
      display: flex;
      align-items: center;
      span {
        margin-right: 32px;
      }
    }
  }
}
.goods-info {
  display: flex;
  align-items: center;
  &.goods {
    cursor: pointer;
  }
  .goods-img {
    flex: none;
    margin-right: 12px;
  }
  .goods-name {
    flex: none;
    max-width: 126px;
    font-size: 14px;
    line-height: 22px;
    color: #0066ff;
    &.shop-name {
      color: #0066ff;
      cursor: pointer;
    }
  }
}
.refresh-btn {
  position: absolute;
  top: 4px;
  right: 0px;
  font-size: 14px;
  color: #1890ff;
}
</style>
